import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import logo from "../../assets/images/boxilogof.png";
// import logo from "../../assets/images/cily-logo.png";
import flagImg from "../../assets/images/flag.png";

import "./footer.css";
import shape2 from "../../assets/images/elements/round-shape-2-new.png";
// import { Form } from "react-bootstrap";
// import storebtn1 from '../../assets/images/icon/store-btn/1.png';
// import storebtn2 from '../../assets/images/icon/store-btn/2.png'

// import { useTranslation } from "react-i18next";

const NewFooter = () => {
  const { t } = useTranslation();
  const handleFBCLick = () => {
    try {
      // console.log('from try');

      window.location = "fb://page/110842648285271";
    } catch (error) {
      // console.log('from catch', error);
      window.open("https://www.facebook.com/110842648285271", "_blank");
    }
  };
  return (
    <footer className='footer-section new-footer-section'>
      <div className='bg-shape--top'>
        <img src={shape2} alt='bg' />
      </div>
      <div className='container pt-55'>
        <div className='row pb-3  justify-content-between'>
          <div className='col-lg-4 d-flex gap-3 flex-column footer-text'>
          <h4>Casino Gambling</h4>
            <ul className='app-btn1'>
              <li className="d-flex">
                <p>Casino Gambling <span>simply dummy text of the printing
and typesetting industry. Lorem Ipsum has
been the industry's standard dummy</span>
                </p>

              </li>
            </ul>

              <div className="select-Lang ">
                <div className="w-100  h-100 d-flex align-items-center justify-content-between gap-2">
                  <div className="w-100 d-flex align-items-center gap-2"><img src={flagImg} alt="arrow"/> English</div>
                  <div><ArrowDown /> </div>
                </div>         
              </div>
              <div className="footer-icon">
                <TwitterIcon /> <DiscordIcon />
              </div>
            
            {/* <select aria-label="Default select example">
      <option> English</option>
      <option value="1">One</option>
      <option value="2">Two</option>
      <option value="3">Three</option>
    </select> */}
          </div>
          <div className='col-lg-3 footer-pl d-flex gap-3 flex-column footer-text'>
            <h4>Allife Program</h4>
            <ul className='app-btn1'>
              <li>
                <Link><span> Promo</span></Link>
              </li>
              <li>
              <Link> <span>Referal</span></Link>
              </li>
              <li>
              <Link> <span>Deposit</span></Link>
              </li>
              <li>
                <Link><span>Bonus</span></Link>
              </li>
            </ul>
          </div>
          <div className='col-lg-3 footer-pl d-flex gap-3 flex-column footer-text'>
          <h4><Link to='/faq'>About Us</Link></h4>
            <ul className='short-links1 justify-content-lg-end justify-content-center'>
              <li>
                <Link to='/contact-us'><span>{t("footer.contact")}</span></Link>
              </li>
              <li>
                <Link to='/agreement'><span>{t("footer.termsOfServices")}</span></Link>
              </li>
              <li>
                <Link to='/privacy'><span>{t("footer.privacy")}</span></Link>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div className='row py-3 align-items-center'>
          <div className='col-lg-12'>
            <p className='copy-right-text text-lg-left text-center mb-3 right-copy-text'>
              {t("footer.copyrightText1")} © 2024 <Link to='/'>Cilycase</Link>. All Rights Reserved.
            </p>
          </div>
          <div className='col-lg-12'>
            <ul className='social-links justify-content-center'>
              <li className='desk-view-url'>
                <a
                  href='/'
                  target='_blank'
                  rel='noreferrer'>
                  <i className='fab fa-facebook-f'></i>
                  
                </a>
              </li>
              <li className='mob-view-url' onClick={handleFBCLick}>
                <span>
                  <i className='fab fa-facebook-f'></i>
                </span>
              </li>
              <li>
                <a
                  href='/'
                  target='_blank'
                  rel='noreferrer'>
                  <i class='fa fa-instagram'></i>
                </a>
              </li>
              <li>
                <a
                  href='/'
                  target='_blank'
                  rel='noreferrer'>
                  <i class='fa fa-youtube-play'></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default NewFooter;

const ArrowDown=()=>{
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
<path d="M6.88867 10.3086L13.5553 16.9753L20.222 10.3086" stroke="white" stroke-width="2.22222" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  )
}

const TwitterIcon=()=>{
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.4879 14.8984L20.9204 23.4993L14.4473 30.4922H15.9041L21.5713 24.3698L26.1503 30.4922H31.108L24.3136 21.4075L30.3387 14.8984H28.8819L23.6626 20.537L19.4456 14.8984H14.4879ZM16.6303 15.9716H18.9079L28.9653 29.4189H26.6877L16.6303 15.9716Z" fill="#757786"/>
<circle cx="22.2222" cy="22.5308" r="21.6667" stroke="#757786" stroke-width="1.11111"/>
</svg>

  )
}

const DiscordIcon=()=>{
  return (
<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_291_579)">
<path d="M32.1099 14.2765C35.118 18.6992 36.6035 23.6878 36.0482 29.4309C36.0458 29.4552 36.0332 29.4775 36.0132 29.4921C33.7353 31.1648 31.5283 32.1799 29.3523 32.8531C29.3353 32.8582 29.3172 32.8579 29.3004 32.8522C29.2836 32.8466 29.2691 32.8358 29.2587 32.8214C28.756 32.1219 28.2992 31.3845 27.899 30.6101C27.8761 30.5645 27.897 30.5096 27.9443 30.4916C28.6698 30.2182 29.3596 29.8906 30.0231 29.5028C30.0754 29.4721 30.0787 29.3972 30.0304 29.3613C29.8896 29.2567 29.7501 29.1469 29.6166 29.037C29.5916 29.0167 29.558 29.0127 29.5297 29.0263C25.2219 31.0156 20.503 31.0156 16.1442 29.0263C16.1159 29.0137 16.0823 29.018 16.058 29.038C15.9248 29.1479 15.785 29.2567 15.6455 29.3613C15.5972 29.3972 15.6012 29.4721 15.6538 29.5028C16.3174 29.8833 17.0072 30.2182 17.7316 30.4929C17.7786 30.5109 17.8009 30.5645 17.7776 30.6101C17.3861 31.3855 16.9293 32.1229 16.4172 32.8224C16.3949 32.8507 16.3583 32.8637 16.3237 32.8531C14.158 32.1799 11.951 31.1648 9.67305 29.4921C9.65407 29.4775 9.64042 29.4542 9.63842 29.4299C9.17432 24.4622 10.1202 19.4323 13.573 14.2755C13.5813 14.2619 13.594 14.2512 13.6086 14.2449C15.3076 13.4651 17.1277 12.8915 19.0301 12.5639C19.0647 12.5586 19.0993 12.5746 19.1173 12.6052C19.3524 13.0214 19.621 13.555 19.8028 13.9912C21.8081 13.6849 23.8446 13.6849 25.8918 13.9912C26.0736 13.5644 26.3329 13.0214 26.567 12.6052C26.5753 12.59 26.5882 12.5778 26.6039 12.5704C26.6196 12.563 26.6372 12.5607 26.6542 12.5639C28.5576 12.8925 30.3777 13.4661 32.0753 14.2449C32.0903 14.2512 32.1026 14.2619 32.1099 14.2765ZM20.8222 23.7218C20.8432 22.2532 19.7725 21.038 18.4285 21.038C17.0954 21.038 16.035 22.2425 16.035 23.7218C16.035 25.2006 17.1164 26.4052 18.4285 26.4052C19.7619 26.4052 20.8222 25.2006 20.8222 23.7218ZM29.6722 23.7218C29.6932 22.2532 28.6225 21.038 27.2788 21.038C25.9454 21.038 24.885 22.2425 24.885 23.7218C24.885 25.2006 25.9664 26.4052 27.2788 26.4052C28.6225 26.4052 29.6722 25.2006 29.6722 23.7218Z" fill="#757786"/>
</g>
<circle cx="22.3335" cy="22.5543" r="21.6667" stroke="#757786" stroke-width="1.11111"/>
<defs>
<clipPath id="clip0_291_579">
<rect width="27.7778" height="20.3111" fill="white" transform="translate(9 12.5547)"/>
</clipPath>
</defs>
</svg>

  )
}